import React, { useState,useRef, useEffect} from "react";
import "../styles/create_issue.css";
import { useDispatch, useSelector } from 'react-redux' 
import { CreateIssue, issuesSelector } from '../slices/issues'	
import confirm, { alert } from "react-alert-confirm";
import { useNavigate } from 'react-router-dom';
import { fetchIssueDetails,StoreApiCallCount,StoreIsRunning,issueCreatedStatus } from '../slices/issues'
import { BlankLoader } from '../pages/BlankLoadingPage';
import { AuthenticatedTemplate } from "@azure/msal-react";

export const Form = () => {
        const [disable,setDisable] = useState(false);
        const [fileError,setFileError] = useState(false);
        const dispatch = useDispatch();
        const disp =useDispatch();
        const [TypeValue, setTypeValue] = useState('');
        const [PriorityValue, setPriorityValue] = useState('');
        const [files,setFiles] = useState();
        const { issueCreated,createdIssueId,apiResponseStatus } = useSelector(issuesSelector)      
        const RefIssueDescription = useRef(null);
        const RefIssueTitle = useRef(null);
        const RefFileInput = useRef(null);
        const RefRequestType = useRef(null);
        const RefPriority = useRef(null);
        const RefSubmit = useRef(null);
        const [formData, setFormData] = useState({});
        const [fileErrorMsg,setFileErrorMsg] = useState();
        const [isAttachment,setIsAttachment] = useState(false);
        const [count,setCount] = useState(0);
        const navigate = useNavigate();
        const [showLoader,setShowLoader] = useState(false);
        let author="";
  

        const submitForm=(e)=>{
          setCount(prevCount=>prevCount+1);
          console.log(formData);                
          dispatch(CreateIssue(formData,files));
          e.preventDefault();
          setDisable(true); 
          setFiles(null);             
        }

        const onInputChange = (e) => {
          console.log("API_URL ",process.env.REACT_APP_API_URL);
          let { name, value } = e.target;
          if (e.target.name === "request_type") {
            setTypeValue(e.target.value);
          }
          if(e.target.name === "priority"){
            setPriorityValue(e.target.value);
          }
          setFormData({ ...formData, [name]: value });
        };       
     
        const fileupload=(e)=>{
          if (e.target.files.length > 0) {
            setCount(prevCount=>prevCount+1);  
            setIsAttachment(true);
            if(e.target.files[0].name.length > 70 || e.target.files[0].size > 5242880) {
                  e.target.files[0].name.length > 70 ? setFileErrorMsg("File name is too long, file name should have less than 70 characters") : setFileErrorMsg("File Size is too large, File size should be less than 5 MB")
                  e.target.value='';
                  setFileError(true);
                
            }else{
                setFiles(e.target.files[0]);
                console.log(e.target.files);
                //var filesArr = Array.prototype.slice.call(e.target.files); 
                console.log(e.target.files[0]);
                //setFiledata(e.target.files[0]);
                setFileError(false);
                console.log(e.target.files[0].size)
            }
          }
        }

        useEffect(()=>{
          if(fileError){openConfirm();}
          console.log(apiResponseStatus);
          if(issueCreated){
            successCreateIssue();
          }else if(apiResponseStatus.length>0){
            failCreateIssue()
          } 
          // eslint-disable-next-line
        },[count,dispatch,issueCreated])
        
        const openConfirm = async () => {
          await alert(fileErrorMsg);
          console.log('ok');
          setFileError(false);
          setIsAttachment(false);
        };
        
        const successCreateIssue = async () => {
          await confirm({
            footer(dispatch) {
              return (
                 <div style={{display:'flex'}}>                
                    <h4>Issue Created Successfully!!</h4>
                    <button onClick={()=>{
                      dispatch(fetchIssueDetails(createdIssueId,author));
                      setShowLoader(true);
                      dispatch(StoreApiCallCount(1));
                      dispatch(StoreIsRunning(true));
                      setTimeout(()=>{
                          setShowLoader(false);    
                          navigate(`/Getissues?id=${createdIssueId}`,{ replace: true });
                      },4000)  
                    }} 
                      type="primary"> Open Created Issue </button>
                    <button style={{backgroundColor:"rgba(92,103,112,255)"}} onClick={()=>
                      {
                        RefIssueTitle.current.value="";
                        RefIssueDescription.current.value="";
                        RefRequestType.current.value="";
                        RefPriority.current.value="";
                        RefFileInput.current.value="";
                        setDisable(false);
                        dispatch("hello");
                        disp(issueCreatedStatus());
                        console.log("executed");
                      }} type="primary"> Report New Issue </button>
                 </div>              
              );
            }
          });

        }
        
        const failCreateIssue = async () => {
          await alert(apiResponseStatus);
          
        };

        function removeAttachment() {
          RefFileInput.current.value="";
          setIsAttachment(false);
        }

  return (
       <AuthenticatedTemplate>
        <div className="column-createIssue">
           {showLoader ? <BlankLoader/> : ""}
        <div style={{width:'50vw',margin: 'auto',paddingTop:'0vh'}}>
          {console.log("issueCreated",issueCreated)} 
         
          
          <form onSubmit={submitForm} style={{marginTop:'2vh'}}>

            <div style={{display:'flex',width:'fit-content', justifyContent:'space-between'}}>
              <p><strong>Request Type</strong></p>
              <p style={{fontWeight:'bolder',color:'red',fontSize:'x-large'}}><sup>&nbsp;*</sup></p>    
            </div>

            <select ref={RefRequestType} style={{width:'100%',height:'auto', borderColor:'#e4e6e8', border:'3px solid #e4e6e8', borderRadius:'8px',padding:'1em'}}
             required value={TypeValue} onChange={onInputChange} name="request_type" disabled={ disable }>
                <option value=""> -- select an option -- </option>
                <option value='Incident'>Incident</option>
                <option value='Service Request'>Service Request</option>
                <option value='Information'>Information</option>
            </select>

            <div style={{display:'flex',width:'fit-content', justifyContent:'space-between',marginTop:'2vh'}}>
              <p><strong>Priority</strong></p>
              <p style={{fontWeight:'bolder',color:'red',fontSize:'x-large'}}><sup>&nbsp;*</sup></p>    
            </div>

            <select ref={RefPriority} style={{width:'100%',height:'auto', borderColor:'#e4e6e8', border:'3px solid #e4e6e8', borderRadius:'8px',padding:'1em'}}
              required value={PriorityValue}
              onChange={onInputChange}
              name="priority"
              disabled={ disable}
              >
                <option value=""> -- select an option -- </option>
                <option value='P1'>P1</option>
                <option value='P2'>P2</option>
                <option value='P3'>P3</option>
            </select>

            <div style={{display:'flex',width:'fit-content', justifyContent:'space-between',marginTop:'2vh'}}>
              <p><strong>Issue Title </strong></p>
              <p style={{fontWeight:'bolder',color:'red',fontSize:'x-large'}}><sup>&nbsp;*</sup></p>    
            </div>    

            <input                
                ref={RefIssueTitle}
                //value={issue_title}
                onChange={onInputChange}
                placeholder="Enter Issue Title / Summary"
                type="text"
                name="issue_title"
                required
                disabled={ disable}
              />

               <div style={{display:'flex',width:'fit-content', justifyContent:'space-between',marginTop:'2vh'}}>
                <p><strong>Issue Description</strong></p>
                <p style={{fontWeight:'bolder',color:'red',fontSize:'x-large'}}><sup>&nbsp;*</sup></p>    
              </div>

              <textarea
                ref={RefIssueDescription}
                rows="5"
                cols="90"        
                onChange={onInputChange}
                placeholder="Enter Issue Description"
                type="text"
                name="issue_description"
                required
                disabled={ disable}
              />
               <div style={{display:'flex',width:'fit-content', justifyContent:'space-between',marginTop:'2vh'}}>
                <p><strong>Attachment</strong>&nbsp;(Optional)</p>
              </div>

              <div style={{display:"flex"}}>  
                <input ref={RefFileInput} type="file" onChange={fileupload} disabled={disable} style={{marginBottom:'2vh'}} />
                <button style={{backgroundColor:'transparent',border:'none'}} className="close" onClick={removeAttachment}  type='button' >
                    <svg height="18px" id="Layer_1" style={{enableBackground:'new 0 0 512 512',visibility: isAttachment ? 'visible':'hidden'}} version="1.1" viewBox="0 0 512 512" width="18px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#69727D" d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
                </button>
              </div>
            
                <button ref={RefSubmit} style={{margin:'auto',width:'15%', height:'7vh',fontSize:'x-large'}} type="submit" disabled={disable} >Submit</button>
                  
          </form>
        </div>
        </div>
        </AuthenticatedTemplate>
  );
};





