import '../styles/layout.css';
import '../styles/navbar.css';
import  {IssueContent}  from "../pages/GetIssues";
import IssueDetails from '../pages/GetIssueDetails';
import IssueTitle from '../pages/IssueTitle';
import CustomIssueFields from '../pages/Customfields';
import { ReplyMessageForm } from './SendReplyForm';
import { issuesSelector} from '../slices/issues';
import { useSelector } from 'react-redux' ;
import { Loader } from './loader';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal, useIsAuthenticated} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { FeedbackForm } from './FeedbackForm';
import { useState,useEffect } from 'react';

export const IssuesDisplay = () => {
        const { issues } = useSelector(issuesSelector);
        const isAuthenticated = useIsAuthenticated();
        const { instance} = useMsal();
        const [showCSAT,setShowCSAT] = useState(false);
        
        if (isAuthenticated) {
                let activeAccount = instance.getActiveAccount();
                //console.log(activeAccount);
                if(!activeAccount){
                    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
                }
        }
        //console.log(issues[0].hasOwnProperty('feedback_rating'));
        useEffect(()=>{
                if(issues[0] && issues[0].state_data.state === 'resolved' && !issues[0].hasOwnProperty('feedback_rating')){
                        setShowCSAT(true);                    
                }else {
                        setShowCSAT(false);
                }
        },[issues])
        
    return(
        <div className="body">
                <AuthenticatedTemplate>
                                   
                        <div className="column-issue-titles" >
                                <IssueContent/>
                        </div>
                        <div className="column-conversation">
                                <div className='tagarea'>
                                        <IssueTitle/>
                                </div>
                                <div className='convbody'>
                                      {!issues ? <Loader/> : <IssueDetails/>}                                
                                </div>
                                <div className='replymsg'>                                
                                {showCSAT ? <FeedbackForm/> : <ReplyMessageForm/> }
                                </div>
                        </div>
                        <div className="column-cif">
                        <span style={{display:'flex',fontWeight:'bold', color:'white',width:'100%',margin:'auto',height:'4vh',alignItems:'center',justifyContent:'center',backgroundColor:'rgba(92,103,112,255)'}}> Issue Details </span>
                                { !issues ? <Loader/> : <CustomIssueFields/> }
                        </div> 
                </AuthenticatedTemplate> 
        </div>
        
    )
    
    
};
