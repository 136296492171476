import { createSlice } from '@reduxjs/toolkit'
import { protectedResources } from "../authConfig";

export const initialState = {
  loading: true,
  hasErrors: false,
  issues: [],
  issueCreated: false,
  messageSent: false,
  isSameUser: false,
  createdIssueId:0,
  timeInterval:0,
  isRunning: true,
  apiCallCount:0,
  apiResponseStatus:[],
  isFeedbackSubmitted:false,
  csatExpired:false
}

// A slice for issues with our 3 reducers
const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    getIssues: state => {
      state.loading = false //This is used in Create Issue with opposite state, show loader when loading is false
    },
    getIssuesSuccess: (state, { payload }) => {
      state.issues = payload
      state.loading = false
      state.hasErrors = false
      state.csatExpired = false
    },
    getMessagesSuccess:(state, {payload}) => {
      state.issues = payload
      state.loading = false
      state.hasErrors = false
      
    },
    getIssuesFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    getIssueCreated:(state,{payload}) => {
      state.loading = false
      state.hasErrors = false
      state.issueCreated = true
      state.createdIssueId = payload
      console.log("create issue id payload",payload)
    },
    getIssueCreationFailed:(state,{payload}) => {
      state.issueCreated = false
      state.hasErrors = true
      state.apiResponseStatus = payload
      console.log("status payload",payload);
    },
    messageSentSuccess: state => {
      state.messageSent = true
      state.hasErrors = false
      state.loading = false
    },
    setReplyMessageAuthentication: (state, {payload}) => {
      state.isSameUser = payload
      //console.log("isSameUser",payload);
    },
    setIsRunning: (state, {payload}) => {
      state.isRunning = payload
      console.log("isRunning",payload)
    },
    setApiCallCount: (state,{payload})=>{
      state.apiCallCount = payload
    },
    setIssueCreated:state=>{
      state.issueCreated = false
      console.log("set to false");
    },
    setIsFeedbackSubmitted:(state,{payload})=>{
      state.isFeedbackSubmitted = payload.status
      state.csatExpired = payload.expired
      // console.log("payload",payload)
      // console.log("status",payload.status)
      // console.log("expired",payload.expired)
    }
   
  },
})


// Three actions generated from the slice
export const {setIsFeedbackSubmitted, getIssues,setIssueCreated,getIssuesSuccess,getIssuesFailure,setApiCallCount,setIsRunning,getMessagesSuccess,getIssueCreated,getIssueCreationFailed,messageSentSuccess, setReplyMessageAuthentication } = issuesSlice.actions
// A selector
export const issuesSelector = state => state.issues;

//export const issueSelector = state => state.messages;
// The reducer
export default issuesSlice.reducer

// Asynchronous thunk action


export function issueCreatedStatus(){
  return async dispatch => {
    dispatch(setIssueCreated());
    console.log("executed redux")
  }
}

export function StoreIsRunning(status){
  return async dispatch => {
    dispatch(setIsRunning(status));
  }
}

export function StoreApiCallCount(count){
  return async dispatch => {
    dispatch(setApiCallCount(count));
  }
}

export function CreateIssue(formData,file) {
  return async dispatch => {
     
      console.log(file);
      const token = localStorage.getItem("SavedToken"); 
      const headers = new Headers();
      const params = {
        issue_title: formData.issue_title,
        issue_description: formData.issue_description,
        request_type: formData.request_type,
        priority: formData.priority
      };

      let query = Object.keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');

      var formdata = new FormData();
      formdata.append("file",file);
      
      headers.append("Authorization", token);

      const options_with_attachment = {
          method: "POST",
          headers:headers,
          body: formdata
      };

      const options_without_attachment = {
          method: "POST",
          headers:headers
      };

      try
      { 
         file ? console.log("with attachment") : console.log("without attachment")
         const response = await fetch(protectedResources.apiCreateIssue.endpoint + '?' + query, file ? options_with_attachment : options_without_attachment)        
         const data = await response.json()
         data.issue_created ? dispatch(getIssueCreated(data.new_issue_json.id)) : dispatch(getIssueCreationFailed(data.status_text))
      }catch(error){
        console.log(error); 
      }
  }
}

  export function fetchIssueDetails(id,userEmail) {
    
      return async dispatch => {
      const email = userEmail       
      const token = localStorage.getItem("SavedToken"); 
      const headers = new Headers();
      const params = {
          issue_id: id 
      };

      let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

      const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify( params )
      };
      
      headers.append("Authorization", token);
       
      try{
            const response = await fetch(protectedResources.apiGetIssueDetails.endpoint+'?'+query, options)  
            const data = await response.json()  
            const conv = data['issue_details'];
            console.log(conv[0]['id']);  
            localStorage.setItem("Status", data.status_text);
           
            if( conv['0'].state_data.state=== ('resolved' || 'rejected')){
              dispatch(setReplyMessageAuthentication(false))
            }else{
              email.toUpperCase() === conv[0].author_email.toUpperCase() ? dispatch(setReplyMessageAuthentication(true)) : dispatch(setReplyMessageAuthentication(false));
            }
            dispatch(getMessagesSuccess(conv))
          }catch(error){
            dispatch(getIssuesFailure())
          }
    }
  }

  export function SendReply(formData,file){
      return async dispatch => {

        console.log(file);
        const token = localStorage.getItem("SavedToken");
        var msgType = file ? "Attachment" : formData.message_type  
        const headers = new Headers();
          const params = {
            issue_id: formData.issue_id,
            user_reply: formData.user_reply,
            message_type: msgType
          };

          let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

          var formdata = new FormData();
          formdata.append("file",file);
          
          const options_with_attachment = {
            method: "POST",
            headers:headers,
            body: formdata
          };
  
          const options_without_attachment = {
            method: "POST",
            headers:headers
          };

         headers.append("Authorization", token);
        try{
              const response = await fetch(protectedResources.apiSendReply.endpoint+'?'+query, file ? options_with_attachment : options_without_attachment)
              const data = await response.json()
              console.log(data['status_text']);
              await dispatch(fetchIssueDetails(formData.issue_id,formData.user_email))
            }catch(error){
              dispatch(getIssuesFailure())
            }
      }
  }

  export function SendFeedback(formData){
    return async dispatch=>{
      var res = {
        status:false,
        expired:false
      }
      dispatch(setIsFeedbackSubmitted(res));

      const params = {
        issue_id: formData.issue_id.toString(),
        feedback_rating: formData.feedback_rating.toString(),
        feedback_comment: formData.feedback_comment.toString()
      };

      const token = localStorage.getItem("SavedToken");
      const headers = new Headers();

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify( params )
      };
      headers.append("Authorization", token);
      try{
        const response = await fetch(protectedResources.apiSendFeedback.endpoint, options)  
        const data = await response.json()
        console.log(response.status);
        if(response.status === 410){
          console.log("inside 410")
          res ={
            status:true,
            expired:true
          }
    
          dispatch(setIsFeedbackSubmitted(res));
        }

        console.log(data['status_text']);  
        
        await dispatch(fetchIssueDetails(formData.issue_id,formData.user_email));
      
      }catch(error){
        console.log("inside catch error ",error);
        res={
          status:false,
          expired:false
        }
        dispatch(setIsFeedbackSubmitted(res));
      }

    }
  }