import React from "react";
import {Table} from "../components/DataTable";
import "../styles/styles.css";
import { useEffect, useState } from "react";
import { useMsal, useAccount} from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";
import { Loader } from "../components/loader";
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { fetchAllIssues, filterIssues, issueListSelector, SaveSelectedFilter } from '../slices/IssueList'	


export const DisplayIssueTable=()=> {
   
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {filteredIssues,hasErrors} = useSelector(issueListSelector);
    const clickhandler = id => {
      console.log(id);
      navigate('/GetIssues?id='+id);
      sessionStorage.setItem("renderCount",1);      
    } 
  
    const { instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [issueData, setIssueData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState('All');
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    let author="";
    let activeAccount = instance.getActiveAccount();

    if(activeAccount && activeAccount.hasOwnProperty('idTokenClaims')){
        if(activeAccount.idTokenClaims.hasOwnProperty('email')){
            author = activeAccount.idTokenClaims.email.toUpperCase();
        }
    }
   
    useEffect(() => {
        
        const headers = new Headers();
        const options = {
            method: "GET",
            headers: headers
        };
       
        const callApiWithToken = async(accessToken) => {  
            const bearer = `Bearer ${accessToken}`;
                headers.append("Authorization", bearer);
                fetch(protectedResources.apiGetIssues.endpoint, options)
                .then(response => response.json())
                .then(response => {
                    setIssueData(response['issues_details']);  
                    dispatch(fetchAllIssues(response['issues_details'],author.toLowerCase()))
                })
                .catch(error => {
                    console.log(error);
                });
        }
        if (account) {
            instance.acquireTokenSilent(request).then(async (response) => {   
                await callApiWithToken(response.accessToken);
                console.log("response",response.idTokenClaims);
                if(!response.idTokenClaims.hasOwnProperty('Organization')){
                    instance.loginRedirect(request);
                }
                sessionStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);
                localStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);
                setLoading(false);               
            }).catch((error) => {
                console.log(error);
            });
        }
        setValue("myopen");
        dispatch(SaveSelectedFilter("myopen"));

    },[account,accounts,instance]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="posts-container">
            <Loader/>
        </div>;
      }else{
        sessionStorage.setItem('issueList', JSON.stringify(issueData));
        localStorage.setItem('issueList', JSON.stringify(issueData));
    }
   
    const handleChange = (e) => {
        setValue(e.target.value);
        const filtered = issueData.filter(issues => {
            if(e.target.value === 'myopen'){
                return (issues.issue_status !== 'resolved' && issues.issue_status !== 'rejected') && issues.author_email.toUpperCase() === author.toUpperCase();
            }
            else if(e.target.value === 'myclosed'){
                return (issues.issue_status === 'resolved' || issues.issue_status === 'rejected') && issues.author_email.toUpperCase() === author.toUpperCase();               
            }
            if(e.target.value === 'All'){
                return issues;
            }
            return filtered;
          });
        dispatch(filterIssues(filtered)); 
        dispatch(SaveSelectedFilter(e.target.value));
    };
   console.log("error",hasErrors);
  return (
    <AuthenticatedTemplate>
      <div style={{paddingLeft:'2vw',paddingRight:'2vw'}}>
        <select value={value} onChange={handleChange} style={{marginTop:'1vh',display:'flex',position:'absolute',zIndex:'9'}}>
            <option value='All'>All Issues</option>
            <option value='myopen'>My Open Issues</option>
            <option value='myclosed'>My Resolved / Rejected Issues</option>
        </select>
        {issueData.length > 0 ?   
            <Table data={filteredIssues} click={clickhandler} />
            : <Loader/>
        } 
      </div>   
      </AuthenticatedTemplate>
    );
}
    

