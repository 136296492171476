
import { MsalProvider,useIsAuthenticated } from '@azure/msal-react';
import { Route, Routes} from 'react-router-dom';
import { PageLayout } from './components/PageLayout';
import './styles/App.css';
import { DisplayIssueTable } from './pages/landingPage';
import { IssuesDisplay } from "./components/IssuesDisplay";
import { Helpcenter } from "./pages/Helpcenter";
import  {Form}  from "./pages/CreateIssue";

const Pages = () => {

    const isAuthenticated = useIsAuthenticated();

    console.log("isAuthenticated",isAuthenticated)

    return ( 
    <Routes>       
        <Route path="/home" element={<DisplayIssueTable/>}/>
        <Route path="/GetIssues" element={<IssuesDisplay /> }/>
        <Route path="/helpcenter" element={<Helpcenter />}/>
        <Route path="/createissue" element={<Form/>}/>
        <Route path="/" element={<DisplayIssueTable />}/>
    </Routes>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>           
            <PageLayout>
                <Pages />
            </PageLayout>              
        </MsalProvider>
    );
};

export default App;