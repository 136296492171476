import React, { useState,useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux' 
import { SendReply, issuesSelector } from '../slices/issues'	
import { Loader } from "../components/loader";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { StoreApiCallCount,StoreIsRunning } from '../slices/issues'
import { alert } from "react-alert-confirm";


export const ReplyMessageForm = () => { 

        const dispatch = useDispatch()
        const { instance} = useMsal();
        const { loading,isSameUser } = useSelector(issuesSelector)
        const query = new URLSearchParams(useLocation().search);
        const id = query.get('id');
        const messageType="Text";
        const RefIssueDescription = useRef(null);
        const [fileError,setFileError] = useState(false);
        const [files,setFiles] = useState();
        const [fileErrorMsg,setFileErrorMsg] = useState();
        const [isAttachment,setIsAttachment] = useState(false);
        const RefFileInput = useRef(null);
        const [count,setCount] = useState(0);

        let userEmail;
        if (instance) {
            userEmail = instance.getActiveAccount().idTokenClaims.email
        }
        
        const openConfirm = async () => {
                await alert(fileErrorMsg);
                console.log('ok');
                setFileError(true);
               // setIsAttachment(false);
        };

        const submitForm=(e)=>{

                dispatch(SendReply(formData,files));
                dispatch(StoreApiCallCount(1));
                dispatch(StoreIsRunning(true));
                RefIssueDescription.current.value="";
                RefFileInput.current.value="";
                setIsAttachment(false);
                setFiles(null);
                console.log("files",files);
                e.preventDefault();
                
        }
        const [formData, setFormData] = useState({
                issue_id: id,
                user_reply: "",
                message_type:messageType,
                user_email: ""
        });
        const updateFormData = e => {
                setFormData({...formData, [e.target.name]: e.target.value,issue_id:id,user_email:userEmail});                
        }   
        const fileupload=(e)=>{
                setCount(prevCount=>prevCount+1);               
                if (e.target.files.length > 0) { 
                 
                        if(e.target.files[0].name.length > 70 || e.target.files[0].size > 5242880) {
                                e.target.files[0].name.length > 70 ? setFileErrorMsg("File name is too long, file name should have less than 70 characters") : setFileErrorMsg("File Size is too large, File size should be less than 5 MB")
                                e.target.value=''
                                setFileError(true);
                                
                        }
                        else{
                                
                                setFiles(e.target.files[0]);
                                console.log(e.target.files);
                                console.log(e.target.files[0]);
                                setFileError(false);
                                setIsAttachment(true);
                                console.log(e.target.files[0].size)       
                        }
                }
        }
      
        
        function removeAttachment() {
                RefFileInput.current.value="";
                setIsAttachment(false);
        }
        useEffect(()=>{
            if(fileError){openConfirm()}; 
             // eslint-disable-next-line
        },[count])


  return (
          <form onSubmit={submitForm}>
           {loading ? <Loader/>:""} 
            <textarea className="textareasendreply"
                ref={RefIssueDescription}
                rows="4"
                cols="0"        
                onChange={e=>updateFormData(e)}
                type="text"
                name="user_reply"
                required
                disabled={!isSameUser}
                placeholder={!isSameUser ? "You can not reply to this Issue":""}
              />
            <div style={{display:"flex"}}>  
                        <div style={{display:'flex'}}>
                        <input type="file" onChange={fileupload} style={{padding:'0.5em',width:'fit-content',border:'none'}} ref={RefFileInput} disabled={!isSameUser} />
                        <button style={{backgroundColor:'transparent',border:'none'}} className="close" onClick={removeAttachment}  type='button' >
                                <svg height="18px" id="Layer_1" style={{enableBackground:'new 0 0 512 512',visibility: isAttachment ? 'visible':'hidden'}} version="1.1" viewBox="0 0 512 512" width="18px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#69727D" d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
                        </button>
                    </div>
                <button className={!isSameUser ? "afterResolvedButtonStyle":"sendreplybutton"} type="submit" disabled={!isSameUser} >Send Reply</button>                       
            </div>
                       
          </form>
  );
};


